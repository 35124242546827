import router from 'next/router';
import { localStorageStores } from 'state/localStorageStores';
import { fireAndForget } from '../../utils/async';
import { createSampleClient } from '../../utils/createSampleClient';
import { HevyWebsocketClient } from '../../utils/globals/HevyWebsocketClient';
import { getLocaleBasedDefaultUnits } from '../../utils/units';
import { CoachSocialLoginResult } from 'hevy-shared';
import { refreshUserContent } from '../../utils/fetchUserContent';
import { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { modal } from 'components/Modals/ModalManager';
import toast from 'react-hot-toast';
import API from '../../utils/API';
import { PlanAggregator } from 'state/aggregators/planAggregatorStore';
import { PushNotifications } from 'utils/globals/pushNotifications';
import { PushAggregator } from 'state/aggregators/pushAggregator';

// Refreshes stores and navigates based on the authentication result properties
export const handleAuthenticationComplete = async (authenticationResult: {
  is_first_login_to_coach_platform?: boolean;
  is_new_user?: boolean;
  hasPickedAUsername?: boolean;
  didJoinTeam: boolean;
}) => {
  const initialRoute = router.query.postLoginPath as string | undefined;
  await refreshUserContent();
  if (
    PushNotifications.pushNotificationPermission === 'granted' &&
    PushNotifications.browserSupportsWorkerBasedNotifications
  ) {
    PushAggregator.registerPush();
  }
  if (authenticationResult.is_first_login_to_coach_platform === false) {
    PlanAggregator.handleInvalidPlanIfNeeded();
  }

  HevyWebsocketClient.startTryingToConnect();
  if (authenticationResult.is_new_user) {
    if (!authenticationResult.didJoinTeam) {
      fireAndForget([createSampleClient(localStorageStores.account.username)]);
    }
    const localeBasedDefaultUnits = getLocaleBasedDefaultUnits();
    const unitPreferencesUpdate = {
      weight_unit: localeBasedDefaultUnits.weight,
      body_measurement_unit: localeBasedDefaultUnits.measurements,
      distance_unit: localeBasedDefaultUnits.distance,
    };
    await localStorageStores.coachConfig.update(unitPreferencesUpdate);
    if (authenticationResult.hasPickedAUsername) {
      router.push(`/onboarding/name`);
    } else {
      router.push(
        `/onboarding/username?includeUsernameStep=${!authenticationResult.hasPickedAUsername}`,
      );
    }
  } else if (authenticationResult.is_first_login_to_coach_platform === true) {
    fireAndForget([createSampleClient(localStorageStores.account.username)]);
    router.push('/onboarding/name');
  } else {
    router.push(initialRoute || '/');
  }
};

export const authorizeWithApple = async (
  inviteShortId?: string,
): Promise<CoachSocialLoginResult> => {
  const result = await (window as any).AppleID?.auth?.signIn();
  const idToken = result.authorization?.id_token;
  const maybeEmail = result.user?.email;
  return await localStorageStores.auth.loginWithApple(idToken, maybeEmail, inviteShortId);
};

export const isGoogleLoginResponse = (
  x: GoogleLoginResponse | GoogleLoginResponseOffline,
): x is GoogleLoginResponse => {
  return x.code === undefined;
};

export const getUsernameErrorDescription = (username: string) => {
  if (username.length < 3 || username.length > 20) {
    return 'Username must contain between 3 and 20 characters';
  }

  if (!username.match(/^[0-9a-z_]*$/)) {
    return 'Username may not contain symbols or spaces, except underscores';
  }

  if (username.startsWith('_') || username.endsWith('_')) {
    return 'Username may not begin or end with an underscore';
  }
};

export const requestPasswordRecovery = async (email: string) => {
  await toast.promise(API.generatePasswordRecoveryEmail(email), {
    loading: 'Sending password reset request',
    success: `Password reset request sent!`,
    error: 'Failed to send email',
  });
  modal.openOkModal({
    title: 'Password Recovery',
    subtitle: `If an account exists with this email, you'll receive an email with instructions for reseting your password shortly. If you're still having trouble accessing your account please contact hello@hevyapp.com`,
    submitButtonTitle: 'Got it!',
  });
};
