import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { LoginViewModel } from './LoginViewModel';
import { LoginCard } from './components/LoginCard';
import { ForgotPasswordCard } from './components/ForgotPasswordCard';
import { AuthenticationScreenWrapper } from '../Components/AuthenticationLayout';
import router from 'next/router';
import { ClipLoader } from 'react-spinners';

export const LoginScreen = observer(() => {
  const tempToken = router.query.token as string;
  const [vm] = useState(() => new LoginViewModel(tempToken));

  return (
    <AuthenticationScreenWrapper>
      {vm.screenState === 'login' && (
        <LoginCard
          usernameOrEmail={vm.usernameOrEmail}
          onUpdateUsernameOrEmail={vm.onUpdateUsernameOrEmail}
          usernameOrEmailErrorStatus={vm.usernameOrEmailErrorStatus}
          password={vm.password}
          onUpdatePassword={vm.onUpdatePassword}
          passwordErrorStatus={vm.passwordErrorStatus}
          isLoading={vm.isLoading}
          onLogin={vm.onLoginPress}
          onAppleLogin={vm.onLoginWithAppleClick}
          onGoogleLogin={vm.onLoginWithGoogleClick}
          loginErrorMessage={vm.errorMessage}
          onForgotPassword={() => vm.setScreenState('forgot-password')}
        />
      )}
      {vm.screenState === 'loading' && <ClipLoader />}
      {vm.screenState === 'forgot-password' && (
        <ForgotPasswordCard
          isEnabled={vm.isSendPasswordRecoveryEnabled}
          isLoading={vm.isPasswordRecoveryEmailLoading}
          onUpdateEmail={vm.onUpdatePasswordRecoveryEmail}
          onSendPasswordRecovery={vm.onSendPasswordRecovery}
          onBackToLogin={() => vm.setScreenState('login')}
        />
      )}
    </AuthenticationScreenWrapper>
  );
});
