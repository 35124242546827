import React from 'react';
import { PrimaryButton, TertiaryButton } from 'styleguide/Buttons';
import { FormInput } from 'styleguide/Inputs';
import { Spacing } from 'styleguide/spacing';
import { DisplayXS, TextSMMedium } from 'styleguide/Texts';
import { View } from 'styleguide/View';

interface Props {
  isEnabled: boolean;
  isLoading: boolean;
  onUpdateEmail: (value: string) => void;
  onSendPasswordRecovery: () => void;
  onBackToLogin: () => void;
}

export const ForgotPasswordCard = ({
  isEnabled,
  isLoading,
  onUpdateEmail,
  onSendPasswordRecovery,
  onBackToLogin,
}: Props) => {
  return (
    <View style={{ maxWidth: 400 }}>
      <View
        style={{
          flex: 1,
        }}
      >
        <DisplayXS style={{ textAlign: 'center', marginBottom: Spacing.md }}>
          Password Recovery
        </DisplayXS>
        <TextSMMedium style={{ marginBottom: Spacing.md }}>
          Enter your email and if an account exists we will send you an email with a link to recover
          your password.
        </TextSMMedium>
        <FormInput
          type={'email'}
          placeholder="Email"
          style={{ marginBottom: Spacing.md }}
          onChange={e => onUpdateEmail(e.currentTarget.value)}
        ></FormInput>
        <PrimaryButton
          onClick={onSendPasswordRecovery}
          title={'Send Password Recovery'}
          style={{ width: '100%' }}
          enabled={isEnabled}
          loading={isLoading}
        />
      </View>
      <TertiaryButton
        onClick={onBackToLogin}
        style={{ width: '100%', marginTop: Spacing.md, textDecoration: 'underline' }}
        title="Back to Log In"
      ></TertiaryButton>
    </View>
  );
};
