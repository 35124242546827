import React from 'react';
import Head from 'next/head';
import { LoginScreen } from 'screens/Authentication/Login/LoginScreen';
import { metaTitle, metaDescription, metaImage } from 'utils/metaTags';
import cookie from 'cookie';
import { GetServerSideProps, GetServerSidePropsResult } from 'next';
import { BootstrapLoadingWrapper } from 'components/BootstrapLoadingWrapper';

function LoginPage() {
  return (
    <>
      <Head>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:site_name" content="Hevy Coach" />
        <meta property="og:image" content={metaImage} />
        <meta property="og:image:width" content="800" />
        <meta property="og:image:height" content="400" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@hevyapp" />
        <meta name="twitter:site" content="@hevyapp" />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content={metaImage} />
      </Head>
      <BootstrapLoadingWrapper>
        <LoginScreen />
      </BootstrapLoadingWrapper>
    </>
  );
}

export const getServerSideProps: GetServerSideProps = async (
  context,
): Promise<GetServerSidePropsResult<{}>> => {
  const rawCookies = context.req?.headers.cookie;
  const cookies = rawCookies ? cookie.parse(rawCookies) : {};
  const authToken = cookies['auth-token'];
  const postLoginPath = context.query.postLoginPath;
  const tempToken = context.query.token as string;

  if (
    authToken &&
    // If there is a temp token in the query,
    // we might want to re-authenticate as a different user.
    // Don't redirect them!
    !tempToken
  ) {
    return {
      redirect: {
        destination: !postLoginPath ? '/' : `${postLoginPath}`,
        permanent: false,
      },
    };
  }

  return {
    props: {
      isAuthenticated: false,
    },
  };
};

export default LoginPage;
